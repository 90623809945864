
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { locations } from '../../constants/locations';

export default defineComponent({
  name: 'MapTooltip',
  props: {
    locationName: { type: String, required: true },
  },
  setup(props) {
    const { t } = useTranslations();
    const location = locations[props.locationName];

    return { location, t };
  },
});
