import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-194e4836"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "block-container" }
const _hoisted_3 = { class: "w-full relative min-h-[75vw] lg:min-h-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_CatalogRequestForm = _resolveComponent("CatalogRequestForm")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "pt-8 lg:pt-13" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseHeading, { size: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('catalog_request_title_1')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseHeading, {
          size: 3,
          class: "lg:mb-8.5"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('catalog_request_title_2')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          icon: "catalog_new_logo",
          alt: "Catalog",
          class: "catalog-icon"
        }),
        _createVNode(_Transition, { name: "change" }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseImage, {
              key: _ctx.images[_ctx.imageIndex],
              icon: _ctx.images[_ctx.imageIndex],
              alt: "Catalog",
              class: "catalog-image"
            }, null, 8, ["icon"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_BaseContainer, { class: "form-container" }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('onSubmit')), ["prevent"]))
          }, [
            _createVNode(_component_CatalogRequestForm),
            _createVNode(_component_BaseText, {
              variant: "16-17",
              class: "optional-text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('catalog_request_required_text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseButton, {
              type: "submit",
              class: "w-full lg:w-max lg:mb-10",
              disabled: !_ctx.isValid
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('catalog_request_btn')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_BaseContainer, { class: "pb-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseButton, {
          variant: "text",
          size: 1,
          component: "a",
          href: _ctx.liebherrLink,
          class: "manufacturer-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('catalog_request_liebherr_link')) + " ", 1),
            _createVNode(_component_BaseIcon, {
              icon: "arrow",
              class: "w-2.5 h-2.5 ml-1 rotate-180"
            })
          ]),
          _: 1
        }, 8, ["href"])
      ]),
      _: 1
    })
  ]))
}