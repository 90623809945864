import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeLandingBlock = _resolveComponent("HomeLandingBlock")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_MapBlock = _resolveComponent("MapBlock")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeLandingBlock),
    (_ctx.categories)
      ? (_openBlock(), _createBlock(_component_Categories, {
          key: 0,
          categories: _ctx.categories,
          class: "categories",
          isHome: ""
        }, null, 8, ["categories"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MapBlock)
  ]))
}