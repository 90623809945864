
import { defineComponent, ref } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import CatalogRequestForm from './CatalogRequestForm.vue';
import { images, liebherrLink } from '../constants/catalog';

export default defineComponent({
  name: 'HomeCatalogRequest',
  props: {
    isValid: { type: Boolean, default: false },
  },
  components: {
    CatalogRequestForm,
  },

  emits: ['onSubmit'],
  setup() {
    const imageIndex = ref(0);
    const changeImage = () => {
      if (imageIndex.value < 2) imageIndex.value += 1;
      else imageIndex.value = 0;
    };

    setInterval(changeImage, 8000);
    const { t } = useTranslations();

    return { t, images, imageIndex, liebherrLink };
  },
});
