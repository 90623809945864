
import { defineComponent, PropType, computed } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { Category } from '@/shared/interfaces/productDetails';
import { routes } from '@/shared/constants/routes';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';

export default defineComponent({
  name: 'Categories',
  props: {
    categories: { type: Array as PropType<Category[]>, requred: true },
    isHome: { type: Boolean, default: false },
  },
  setup(props) {
    const { t } = useTranslations();
    const { categoriesIcons, defaultCategoryIcon } = useFallbackImage();
    const categoriesButtonClass = computed(() =>
      props.isHome ? 'categories-button-home' : 'categories-button-list'
    );

    return { routes, categoriesButtonClass, t, defaultCategoryIcon, categoriesIcons };
  },
});
