import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b21a326"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative h-full" }
const _hoisted_2 = {
  key: 0,
  class: "card-new-label"
}
const _hoisted_3 = { class: "card-container" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "image-container" }
const _hoisted_6 = { class: "flex text-white lg:items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.product.newProduct)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t('product_card_new_label')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.product.imageUrl)
            ? (_openBlock(), _createBlock(_component_ProductImage, {
                key: 0,
                src: _ctx.product.imageUrl,
                alt: "Product",
                class: "w-full"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_BaseImage, {
                key: 1,
                icon: "product_default_thumbnail",
                alt: "Product",
                class: "w-full"
              }))
        ]),
        _createVNode(_component_BaseText, {
          variant: "16-17",
          class: "name-text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.product.name), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseText, { class: "view-product-text" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('new_product_view')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseIcon, {
          icon: "arrow",
          class: "icon"
        })
      ])
    ])
  ]))
}