import { TranslationFun } from '@/shared/composables/useTranslations';
import { ContactCarouselItem } from '@/shared/components/ContactCarousel/ContactCarouseltem';
import contactsFlag from '@/assets/contacts/contacts_flag.png';
import contactsLocation from '@/assets/contacts/contacts_location.png';

export const contactsList = (t: TranslationFun): ContactCarouselItem[] => {
  const formatPhoneNumber = (phoneNumber: string): string => {
    return `tel:${phoneNumber.replace(/[\s-]/g, '')}`;
  };

  return [
    {
      image: contactsFlag,
      title: t('contact_carousel_1_title'),
      label: t('contact_carousel_1_phone'),
      link: formatPhoneNumber(t('contact_carousel_1_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_2_title'),
      label: t('contact_carousel_2_phone'),
      link: formatPhoneNumber(t('contact_carousel_2_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_3_title'),
      label: t('contact_carousel_3_phone'),
      link: formatPhoneNumber(t('contact_carousel_3_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_4_title'),
      label: t('contact_carousel_4_phone'),
      link: formatPhoneNumber(t('contact_carousel_4_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_5_title'),
      label: t('contact_carousel_5_phone'),
      link: formatPhoneNumber(t('contact_carousel_5_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_6_title'),
      label: t('contact_carousel_6_phone'),
      link: formatPhoneNumber(t('contact_carousel_6_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_7_title'),
      label: t('contact_carousel_7_phone'),
      link: formatPhoneNumber(t('contact_carousel_7_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_8_title'),
      label: t('contact_carousel_8_phone'),
      link: formatPhoneNumber(t('contact_carousel_8_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_9_title'),
      label: t('contact_carousel_9_phone'),
      link: formatPhoneNumber(t('contact_carousel_9_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_10_title'),
      label: t('contact_carousel_10_phone'),
      link: formatPhoneNumber(t('contact_carousel_10_phone')),
    },
    {
      image: contactsLocation,
      title: t('contact_carousel_11_title'),
      label: t('contact_carousel_11_phone'),
      link: formatPhoneNumber(t('contact_carousel_11_phone')),
    },
  ];
};
