
import { defineComponent, PropType } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';
import { Product } from '@/shared/interfaces/product';
import NewProductCard from '@/shared/components/ProductCard/NewProductCard.vue';

export default defineComponent({
  name: 'HomeNewProducts',
  components: { NewProductCard },
  props: { products: { type: Array as PropType<Product[]>, required: true } },
  setup() {
    const { t } = useTranslations();
    const { isLm } = useVersions();

    const title1 = isLm ? t('home_new_products_title1_lm') : t('home_new_products_title1');
    const title2 = isLm ? t('home_new_products_title2_lm') : t('home_new_products_title2');

    return { routes, t, title1, title2, isLm };
  },
});
