import { ref, Ref } from 'vue';

interface UseMapTooltip {
  selectedPin: Ref<number>;
  select: (item: number) => void;
  deselect: (item: number) => void;
  isSelected: (item: number) => boolean;
}

export const useMapTooltip = (): UseMapTooltip => {
  const selectedPin = ref(0);

  const select = (item: number) => {
    selectedPin.value = item;
  };

  const deselect = () => {
    selectedPin.value = 0;
  };

  const isSelected = (item: number) => item === selectedPin.value;

  return {
    selectedPin,
    select,
    deselect,
    isSelected,
  };
};
