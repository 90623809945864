import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65a607e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white relative flex flex-col" }
const _hoisted_2 = { class: "lg:hidden overflow-hidden" }
const _hoisted_3 = { class: "lg:w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mobileMap[_ctx.version])))
    ]),
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.desktopMap[_ctx.version]), { class: "hidden lg:block" })),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseHeading, {
            size: 3,
            class: "map-title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.mapInfo[_ctx.version].title}`)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseText, { class: "map-text" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.mapInfo[_ctx.version].text}`)), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}