interface ILocation {
  name: string;
  street: string;
  city: string;
  workHours: string;
  workHoursFriday: string;
  mapLink: string;
}

interface ILocations {
  [key: string]: ILocation;
}

export const locations: ILocations = {
  hannover: {
    name: 'map_hannover_name',
    street: 'map_hannover_street',
    city: 'map_hannover_city',
    workHours: 'map_hannover_work_hours',
    workHoursFriday: 'map_hannover_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Hannover?share',
  },
  wolfsburg: {
    name: 'map_wolfsburg_name',
    street: 'map_wolfsburg_street',
    city: 'map_wolfsburg_city',
    workHours: 'map_wolfsburg_work_hours',
    workHoursFriday: 'map_wolfsburg_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Wolfsburg?share',
  },
  hildesheim: {
    name: 'map_hildesheim_name',
    street: 'map_hildesheim_street',
    city: 'map_hildesheim_city',
    workHours: 'map_hildesheim_work_hours',
    workHoursFriday: 'map_hildesheim_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Hildesheim?share',
  },
  braunschweig: {
    name: 'map_braunschweig_name',
    street: 'map_braunschweig_street',
    city: 'map_braunschweig_city',
    workHours: 'map_braunschweig_work_hours',
    workHoursFriday: 'map_braunschweig_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Braunschweig?share',
  },
  einbeck: {
    name: 'map_einbeck_name',
    street: 'map_einbeck_street',
    city: 'map_einbeck_city',
    workHours: 'map_einbeck_work_hours',
    workHoursFriday: 'map_einbeck_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Einbeck?share',
  },
  magdeburg: {
    name: 'map_magdeburg_name',
    street: 'map_magdeburg_street',
    city: 'map_magdeburg_city',
    workHours: 'map_magdeburg_work_hours',
    workHoursFriday: 'map_magdeburg_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Magdeburg?share',
  },
  gottingen: {
    name: 'map_gottingen_name',
    street: 'map_gottingen_street',
    city: 'map_gottingen_city',
    workHours: 'map_gottingen_work_hours',
    workHoursFriday: 'map_gottingen_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Goettingen?share',
  },
  kassel: {
    name: 'map_kassel_name',
    street: 'map_kassel_street',
    city: 'map_kassel_city',
    workHours: 'map_kassel_work_hours',
    workHoursFriday: 'map_kassel_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Kassel?share',
  },
  giessen: {
    name: 'map_giessen_name',
    street: 'map_giessen_street',
    city: 'map_giessen_city',
    workHours: 'map_giessen_work_hours',
    workHoursFriday: 'map_giessen_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Giessen?share',
  },
  nordhausen: {
    name: 'map_nordhausen_name',
    street: 'map_nordhausen_street',
    city: 'map_nordhausen_city',
    workHours: 'map_nordhausen_work_hours',
    workHoursFriday: 'map_nordhausen_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Nordhausen?share',
  },
  sangerhausen: {
    name: 'map_sangerhausen_name',
    street: 'map_sangerhausen_street',
    city: 'map_sangerhausen_city',
    workHours: 'map_sangerhausen_work_hours',
    workHoursFriday: 'map_sangerhausen_work_hours_friday',
    mapLink: 'https://g.page/Kurt_Koenig_Sangerhausen?share',
  },
  gdansk: {
    name: 'map_gdansk_name',
    street: 'map_gdansk_street',
    city: 'map_gdansk_city',
    workHours: 'map_gdansk_work_hours',
    workHoursFriday: 'map_gdansk_work_hours_friday',
    mapLink: 'https://goo.gl/maps/8fD8iTe2MsJKYv1j7',
  },
  gdynia: {
    name: 'map_gdynia_name',
    street: 'map_gdynia_street',
    city: 'map_gdynia_city',
    workHours: 'map_gdynia_work_hours',
    workHoursFriday: 'map_gdynia_work_hours_friday',
    mapLink: 'https://goo.gl/maps/aDezHqwBrjkq1SERA',
  },
  slupsk: {
    name: 'map_slupsk_name',
    street: 'map_slupsk_street',
    city: 'map_slupsk_city',
    workHours: 'map_slupsk_work_hours',
    workHoursFriday: 'map_slupsk_work_hours_friday',
    mapLink: 'https://goo.gl/maps/sJ9e7vbtjAELyjPTA',
  },
  grudziadz: {
    name: 'map_grudziadz_name',
    street: 'map_grudziadz_street',
    city: 'map_grudziadz_city',
    workHours: 'map_grudziadz_work_hours',
    workHoursFriday: 'map_grudziadz_work_hours_friday',
    mapLink: 'https://goo.gl/maps/hamVzxwHDCuUXieBA',
  },
  riga_hanzas: {
    name: 'map_riga_hanzas_name',
    street: 'map_riga_hanzas_street',
    city: 'map_riga_hanzas_city',
    workHours: 'map_riga_hanzas_work_hours',
    workHoursFriday: 'map_riga_hanzas_work_hours_friday',
    mapLink: 'https://goo.gl/maps/hLVmfSMTQCdZFYW48',
  },
  riga_granita: {
    name: 'map_riga_granita_name',
    street: 'map_riga_granita_street',
    city: 'map_riga_granita_city',
    workHours: 'map_riga_granita_work_hours',
    workHoursFriday: 'map_riga_granita_work_hours_friday',
    mapLink: 'https://goo.gl/maps/hLVmfSMTQCdZFYW48',
  },
  vilnius: {
    name: 'map_vilnius_name',
    street: 'map_vilnius_street',
    city: 'map_vilnius_city',
    workHours: 'map_vilnius_work_hours',
    workHoursFriday: 'map_vilnius_work_hours_friday',
    mapLink: 'https://goo.gl/maps/pUp2BYTNbjCJ8tYU6',
  },
  kaunas: {
    name: 'map_kaunas_name',
    street: 'map_kaunas_street',
    city: 'map_kaunas_city',
    workHours: 'map_kaunas_work_hours',
    workHoursFriday: 'map_kaunas_work_hours_friday',
    mapLink: 'https://goo.gl/maps/S5CEiRizT7QcdMJx7',
  },
  klaipeda: {
    name: 'map_klaipeda_name',
    street: 'map_klaipeda_street',
    city: 'map_klaipeda_city',
    workHours: 'map_klaipeda_work_hours',
    workHoursFriday: 'map_klaipeda_work_hours_friday',
    mapLink: 'https://goo.gl/maps/GCsJk2fUEMDnjatg7',
  },
};
