import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeLM = _resolveComponent("HomeLM")!
  const _component_HomeMarkets = _resolveComponent("HomeMarkets")!
  const _component_Home = _resolveComponent("Home")!

  return (_ctx.isLm)
    ? (_openBlock(), _createBlock(_component_HomeLM, { key: 0 }))
    : (_ctx.isMarkets)
      ? (_openBlock(), _createBlock(_component_HomeMarkets, { key: 1 }))
      : (_openBlock(), _createBlock(_component_Home, { key: 2 }))
}