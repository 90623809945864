import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_InputGroup, {
      label: _ctx.t('catalog_request_company_label'),
      error: _ctx.form.companyErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.company,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.company) = $event)),
          name: "company"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('email_label'),
      error: _ctx.form.emailErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
          name: "email"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputGroup, {
        label: _ctx.t('firstname_label'),
        error: _ctx.form.firstnameErr,
        class: "lg:w-1/2 lg:mr-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.firstname,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.firstname) = $event)),
            name: "firstname"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('catalog_request_lastname_label'),
        error: _ctx.form.lastnameErr,
        class: "lg:w-1/2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.lastname,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.lastname) = $event)),
            name: "lastname"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"])
    ]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('catalog_request_address_label'),
      error: _ctx.form.addressErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.address,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.address) = $event)),
          name: "address"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputGroup, {
        label: _ctx.t('catalog_request_postcode_label'),
        error: _ctx.form.zipErr,
        class: "mr-1.5 lg:mr-3 w-1/3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.zip,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.zip) = $event)),
            name: "zip"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('catalog_request_city_label'),
        error: _ctx.form.cityErr,
        class: "w-2/3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.city,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.city) = $event)),
            name: "city"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"])
    ])
  ], 64))
}