
import { defineComponent, onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useCategories } from '@/pages/ProductsList/composables/useCategories';
import { useNewProducts } from '@/pages/NewProducts/composables/useNewProducts';
import { useOpenable } from '@/shared/composables/useOpenable';
import Categories from '@/shared/components/Categories/Categories.vue';
import { CATALOG_REQUEST_INITIAL_DATA } from '@/shared/constants/catalogRequest';
import { CatalogRequest } from '@/shared/interfaces/catalogRequest';
import ContactCarousel from '@/shared/components/ContactCarousel/ContactCarousel.vue';
import { useCatalogRequest } from './composables/useCatalogRequest';
import { catalogValidationSchema } from './composables/catalogValidationSchema';
import HomeLandingBlock from './components/HomeLandingBlock.vue';
import HomeNewProducts from './components/HomeNewProducts.vue';
import HomeCatalogRequest from './components/HomeCatalogRequest.vue';
import MapBlock from './components/MapBlock.vue';
import RequestSuccessModal from './components/RequestSuccessModel.vue';
import BaseContainer from '@/shared/components/BaseComponents/BaseContainer/BaseContainer.vue';
import { contactsList } from '@/pages/Home/constants/contactsList';
import { useVersions } from '@/shared/composables/useVersions';

const pageSize = 3;

export default defineComponent({
  name: 'Home',
  components: {
    BaseContainer,
    ContactCarousel,
    HomeLandingBlock,
    HomeNewProducts,
    HomeCatalogRequest,
    MapBlock,
    Categories,
    RequestSuccessModal,
  },
  setup() {
    const { categories } = useCategories();
    const { t } = useTranslations();
    const router = useRouter();
    const { isDe } = useVersions();
    const { products, getNewProducts } = useNewProducts();
    const { isOpen: showModal, open: openModal, close } = useOpenable();
    const { postRequest, isSuccess, error: requestError } = useCatalogRequest();
    const isCatalogRequested = ref(false);

    const slides = contactsList(t);

    const { values, meta } = useForm({
      validationSchema: catalogValidationSchema(t),
      initialValues: CATALOG_REQUEST_INITIAL_DATA,
      validateOnMount: false,
    });

    const onSubmit = async () => {
      await postRequest(values as CatalogRequest);
      if (isSuccess.value) openModal();
      else router.push({ path: routes.error.path });
    };

    const closeModal = () => {
      isCatalogRequested.value = true;
      close();
    };

    onMounted(async () => {
      const options = { page: 1, pageSize };
      getNewProducts(options);
    });

    return {
      categories,
      products,
      routes,
      t,
      onSubmit,
      meta,
      showModal,
      closeModal,
      isCatalogRequested,
      requestError,
      slides,
      isDe,
    };
  },
});
