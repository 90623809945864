import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17d80e48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "block-container" }
const _hoisted_3 = { class: "w-full lg:w-3/5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_LmRequestForm = _resolveComponent("LmRequestForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "pt-8 lg:pt-13 mb-4 lg:mb-8" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseHeading, {
          size: 3,
          class: "lg:pr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('lm_request_title')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseImage, {
          icon: "lm_request_logo",
          alt: "Request",
          class: "request-image"
        })
      ]),
      _createVNode(_component_LmRequestForm, {
        onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit'))),
        isValid: _ctx.isValid
      }, null, 8, ["isValid"])
    ])
  ]))
}