import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, { opened: _ctx.opened }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        size: 5,
        class: "pb-3 lg:pb-5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('catalog_request_success_title')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, { class: "lg:text-20 mb-1.5" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('catalog_request_success_text1')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, { class: "lg:text-20 mb-5.5 lg:mb-7.5" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('catalog_request_success_text2')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: _ctx.routes.home.path,
        custom: ""
      }, {
        default: _withCtx(({ navigate }) => [
          _createVNode(_component_BaseButton, {
            class: "w-max",
            size: 2,
            variant: "primary",
            onClick: ($event: any) => {
          _ctx.$emit('close');
          navigate();
        }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('catalog_request_success_btn')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _: 1
  }, 8, ["opened"]))
}