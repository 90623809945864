
import { defineComponent } from 'vue';
import { useMapTooltip } from '../../composables/useMapTooltip';
import MapTooltipSmall from './MapTooltipSmall.vue';

export default defineComponent({
  name: 'LocationsMapSmall',
  components: { MapTooltipSmall },
  setup() {
    const { selectedPin, select, deselect, isSelected } = useMapTooltip();

    return { selectedPin, select, deselect, isSelected };
  },
});
