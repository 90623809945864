
import { defineComponent } from 'vue';
import { useVersions } from '@/shared/composables/useVersions';
import Home from './Home.vue';
import HomeLM from './HomeLM.vue';
import HomeMarkets from './HomeMarkets.vue';

export default defineComponent({
  name: 'HomeWrapper',
  components: {
    Home,
    HomeLM,
    HomeMarkets,
  },
  setup() {
    const { isLm, isMarkets } = useVersions();
    return { isLm, isMarkets };
  },
});
