
import { defineComponent } from 'vue';
import { useLmRequestFields } from '../composables/useLmRequestFields';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'LmRequestForm',
  emits: ['submit'],
  props: {
    isValid: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useTranslations();
    const { form } = useLmRequestFields();
    return {
      t,
      form,
    };
  },
});
