import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { CatalogRequestKey } from '../constants/injectables';
import { CatalogRequest } from '@/shared/interfaces/catalogRequest';

interface IRequestResponse {
  isSuccess: Ref<boolean>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  postRequest: (values: CatalogRequest) => Promise<void>;
}

export const useCatalogRequest = (): IRequestResponse => {
  const service = injectStrict(CatalogRequestKey);
  const isSuccess = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const postRequest = async (values: CatalogRequest) => {
    const { address } = values;
    const transformedValue = { ...values, street_and_no: address };
    isLoading.value = true;

    try {
      const res = await service.requestCatalog(transformedValue);
      if (res.status === 200) {
        isSuccess.value = true;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    postRequest,
    isSuccess,
    error,
    isLoading,
  };
};
