import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeLandingBlockLM = _resolveComponent("HomeLandingBlockLM")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_MapBlock = _resolveComponent("MapBlock")!
  const _component_HomeNewProducts = _resolveComponent("HomeNewProducts")!
  const _component_HomeLMRequest = _resolveComponent("HomeLMRequest")!
  const _component_RequestLmSuccessModal = _resolveComponent("RequestLmSuccessModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeLandingBlockLM),
    (_ctx.categories)
      ? (_openBlock(), _createBlock(_component_Categories, {
          key: 0,
          categories: _ctx.categories,
          class: "categories"
        }, null, 8, ["categories"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MapBlock),
    (_ctx.products)
      ? (_openBlock(), _createBlock(_component_HomeNewProducts, {
          key: 1,
          products: _ctx.products
        }, null, 8, ["products"]))
      : _createCommentVNode("", true),
    (!_ctx.isLmRequested)
      ? (_openBlock(), _createBlock(_component_HomeLMRequest, {
          key: 2,
          onSubmit: _ctx.onSubmit,
          isValid: _ctx.meta.valid
        }, null, 8, ["onSubmit", "isValid"]))
      : _createCommentVNode("", true),
    _createVNode(_component_RequestLmSuccessModal, {
      opened: _ctx.showModal,
      onClose: _ctx.closeModal
    }, null, 8, ["opened", "onClose"])
  ]))
}