
import { defineComponent } from 'vue';
import { useMapTooltip } from '../../composables/useMapTooltip';
import MapTooltip from './MapTooltip.vue';

export default defineComponent({
  name: 'LocationsMap',
  components: { MapTooltip },
  setup() {
    const { selectedPin, select, deselect, isSelected } = useMapTooltip();

    return { selectedPin, select, deselect, isSelected };
  },
});
