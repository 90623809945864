import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0ffc21e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_NewProductCard = _resolveComponent("NewProductCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createBlock(_component_BaseContainer, { class: "py-8 lg:py-7" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        size: 3,
        class: "text-white inline lg:block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title1), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseHeading, {
        size: 3,
        class: _normalizeClass(["text-white inline", { 'lm-title': _ctx.isLm }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title2), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: product.id,
            to: _ctx.routes.productDetails.path(product.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NewProductCard, { product: product }, null, 8, ["product"])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128)),
        _createVNode(_component_BaseButton, {
          variant: "secondary",
          color: "white",
          component: "router-link",
          to: _ctx.routes.newProducts.path(_ctx.products[0]?.id)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseText, {
              variant: "15",
              class: "all-products-text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('home_new_products_btn')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseIcon, {
              icon: "arrow",
              class: "all-products-icon"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}