import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductsListKey } from '@/pages/ProductsList/constants/injectables';
import { Product } from '@/shared/interfaces/product';

interface IOptions {
  id?: string;
  page?: number;
  pageSize?: number;
  resetProducts?: boolean;
}

interface INewProducts {
  products: Ref<Product[]>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  isLastPage: Ref<boolean>;
  getNewProducts: (options: IOptions) => Promise<void>;
}

export const useNewProducts = (): INewProducts => {
  const service = injectStrict(ProductsListKey);
  const products = ref<Product[]>([]);
  const isLoading = ref<boolean>(false);
  const isLastPage = ref<boolean>(false);
  const error = ref<boolean>(false);

  const getNewProducts = async (options: IOptions) => {
    const { page, pageSize = 12, resetProducts = false } = options;
    isLoading.value = true;
    if (resetProducts) {
      products.value = [];
    }
    try {
      const res = await service.getNewProducts(page, pageSize);
      if (res.status === 200) {
        products.value = [...products.value, ...res.data];
        isLastPage.value = res.data.length < pageSize;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    getNewProducts,
    products,
    error,
    isLoading,
    isLastPage,
  };
};
