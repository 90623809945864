import { onMounted, ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductsListKey } from '../constants/injectables';
import { Category } from '@/shared/interfaces/productDetails';

interface IUseCategories {
  categories: Ref<Category[]>;
  error: Ref<unknown>;
  isLoading: Ref<boolean>;
}

export const useCategories = (): IUseCategories => {
  const service = injectStrict(ProductsListKey);
  const categories = ref();
  const isLoading = ref<boolean>(false);
  const error = ref();

  onMounted(async () => {
    isLoading.value = true;
    try {
      const res = await service.getProductsCategories();
      if (res.status === 200) {
        categories.value = res.data;
      } else {
        error.value = res;
      }
    } catch (err) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  });

  return {
    categories,
    error,
    isLoading,
  };
};
