import { object, string } from 'yup';
import { postcodeRegEx } from '@/shared/constants/regExpressions';
import { TranslationFun } from '@/shared/composables/useTranslations';

export const catalogValidationSchema = (t: TranslationFun) =>
  object({
    company: string(),
    firstname: string().required(t('error_message_firstname')),
    lastname: string().required(t('error_message_lastname')),
    address: string().required(t('error_message_address')),
    zip: string()
      .matches(postcodeRegEx, {
        message: t('error_message_zip'),
      })
      .required(t('error_message_zip')),
    city: string().required(t('error_message_city')),
    email: string().email(t('error_message_email_valid')).required(t('error_message_email')),
  });
