import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { LmRequestKey } from '../constants/injectables';
import { LmRequest } from '@/shared/interfaces/lmRequest';

interface LmRequestResponse {
  isSuccess: Ref<boolean>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  postRequest: (values: LmRequest) => Promise<void>;
}

export const useLmRequest = (): LmRequestResponse => {
  const service = injectStrict(LmRequestKey);
  const isSuccess = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const postRequest = async (values: LmRequest) => {
    isLoading.value = true;

    try {
      const res = await service.requestLm(values as LmRequest);
      if (res.status === 200) {
        isSuccess.value = true;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    postRequest,
    isSuccess,
    error,
    isLoading,
  };
};
