
import { defineComponent } from 'vue';
import { useCatalogFields } from '../composables/useCatalogFields';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'CatalogRequestForm',
  emits: ['onSubmit'],
  setup() {
    const { t } = useTranslations();
    const { form } = useCatalogFields();
    return {
      t,
      form,
    };
  },
});
