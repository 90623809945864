export const mapInfo = {
  de: {
    title: 'map_title',
    text: 'map_text',
    btn: 'map_button',
    phone: '03631476555',
  },
  lm: {
    title: 'map_title',
    text: 'map_text_lm',
    btn: 'map_button_lm',
    phone: '055617901856',
  },
  pl: {
    title: 'map_title',
    text: 'map_text',
    btn: 'map_button',
    phone: '503640055',
  },
  lv: {
    title: 'map_title',
    text: 'map_text',
    btn: 'map_button',
    phone: '+37127371666',
  },
  lt: {
    title: 'map_title',
    text: 'map_text',
    btn: 'map_button',
    phone: '***ADD NUMBER***',
  },
};
