import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c0275ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-28 lg:text-40 2xl:text-60 font-black mb-4 lg:mb-6 whitespace-pre-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeLandingBlock = _resolveComponent("HomeLandingBlock")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_MapBlock = _resolveComponent("MapBlock")!
  const _component_ContactCarousel = _resolveComponent("ContactCarousel")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_HomeNewProducts = _resolveComponent("HomeNewProducts")!
  const _component_HomeCatalogRequest = _resolveComponent("HomeCatalogRequest")!
  const _component_RequestSuccessModal = _resolveComponent("RequestSuccessModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeLandingBlock),
    (_ctx.categories)
      ? (_openBlock(), _createBlock(_component_Categories, {
          key: 0,
          categories: _ctx.categories,
          class: "categories",
          isHome: ""
        }, null, 8, ["categories"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MapBlock),
    (_ctx.isDe)
      ? (_openBlock(), _createBlock(_component_BaseContainer, {
          key: 1,
          class: "py-8 lg:mb-18 bg-white overflow-hidden"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.t('landing_page_contacts_block_title')), 1),
            _createVNode(_component_ContactCarousel, { slides: _ctx.slides }, null, 8, ["slides"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.products)
      ? (_openBlock(), _createBlock(_component_HomeNewProducts, {
          key: 2,
          products: _ctx.products
        }, null, 8, ["products"]))
      : _createCommentVNode("", true),
    (!_ctx.isCatalogRequested)
      ? (_openBlock(), _createBlock(_component_HomeCatalogRequest, {
          key: 3,
          onOnSubmit: _ctx.onSubmit,
          isValid: _ctx.meta.valid
        }, null, 8, ["onOnSubmit", "isValid"]))
      : _createCommentVNode("", true),
    _createVNode(_component_RequestSuccessModal, {
      opened: _ctx.showModal,
      onClose: _ctx.closeModal
    }, null, 8, ["opened", "onClose"])
  ]))
}