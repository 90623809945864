import LocationsMap from '../components/maps/LocationsMap.vue';
import LocationsMapPl from '../components/maps/LocationsMapPl.vue';
import LocationsMapLv from '../components/maps/LocationsMapLv.vue';
import LocationsMapLt from '../components/maps/LocationsMapLt.vue';
import LocationsMapSmall from '../components/maps/LocationsMapSmall.vue';
import LocationsMapSmallPl from '../components/maps/LocationsMapSmallPl.vue';
import LocationsMapSmallLv from '../components/maps/LocationsMapSmallLv.vue';
import LocationsMapSmallLt from '../components/maps/LocationsMapSmallLt.vue';

export const mobileMap = {
  de: LocationsMapSmall,
  lm: LocationsMapSmall,
  pl: LocationsMapSmallPl,
  lv: LocationsMapSmallLv,
  lt: LocationsMapSmallLt,
};
export const desktopMap = {
  de: LocationsMap,
  lm: LocationsMap,
  pl: LocationsMapPl,
  lv: LocationsMapLv,
  lt: LocationsMapLt,
};
