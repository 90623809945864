
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { routes } from '@/shared/constants/routes';

export default defineComponent({
  name: 'RequestSuccessModal',
  inheritAttrs: false,
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup() {
    const { t } = useTranslations();
    return { t, routes };
  },
});
