import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dfffd1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-[100%] max-h-[63.5rem] relative px-6" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "px-2 py-2.5 flex flex-col items-center max-w-full w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full", { 'carousel-overflow': _ctx.allowOverflow }])
  }, [
    _createVNode(_component_Swiper, _mergeProps({
      "slides-per-view": "auto",
      scrollbar: { enabled: true, hide: false, draggable: true },
      mousewheel: { forceToAxis: true },
      modules: [_ctx.Mousewheel, _ctx.Scrollbar]
    }, _ctx.swiperProps), {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            key: slide.title,
            class: "contact-slide relative"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: slide.image,
                  alt: slide.title,
                  class: "object-contain image-field select-none m-auto"
                }, null, 8, _hoisted_2)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createVNode(_component_BaseText, {
                    variant: "18-20",
                    class: "font-black block 2xl:text-[25px]"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(slide.title), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_BaseText, {
                    component: "a",
                    href: slide.link,
                    variant: "20-25",
                    class: _normalizeClass(["font-black block text-gray-1600 2xl:text-35 break-all break-words", { 'hover:underline': !!slide.link, 'cursor-default': !slide.link }])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(slide.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["href", "class"])
                ])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 16, ["modules"])
  ], 2))
}