
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import { mapInfo } from '../constants/mapInfo';
import { mobileMap, desktopMap } from '../constants/mapComponentsDictionary';

export default defineComponent({
  name: 'MapBlock',
  setup() {
    const { t } = useTranslations();
    const version = getConfig().appVersion;

    return { t, mapInfo, version, mobileMap, desktopMap };
  },
});
