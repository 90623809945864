import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3139956"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col lg:flex-row" }
const _hoisted_2 = { class: "submit-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"])),
    class: "form-container"
  }, [
    _createVNode(_component_InputGroup, {
      label: _ctx.t('lm_request_company_label'),
      error: _ctx.form.companyErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.company,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.company) = $event)),
          name: "company"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputGroup, {
        label: _ctx.t('lm_request_firstname_label'),
        error: _ctx.form.firstnameErr,
        class: "lg:w-1/2 lg:mr-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.firstname,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.firstname) = $event)),
            name: "firstname"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('lm_request_lastname_label'),
        error: _ctx.form.lastnameErr,
        class: "lg:w-1/2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.lastname,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.lastname) = $event)),
            name: "lastname"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"])
    ]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('lm_request_email_label'),
      error: _ctx.form.emailErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
          name: "email"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('lm_request_tel_label'),
      error: _ctx.form.telErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, {
          modelValue: _ctx.form.tel,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.tel) = $event)),
          name: "tel"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('lm_request_comments_label'),
      error: _ctx.form.commentsErr
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTextArea, {
          modelValue: _ctx.form.comments,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.comments) = $event)),
          class: "request-comments",
          name: "comments"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseText, {
        variant: "16-17",
        class: "optional-text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('lm_request_required_text')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseButton, {
        type: "submit",
        class: "w-full lg:w-max",
        disabled: !_ctx.isValid
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('lm_request_btn')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 32))
}