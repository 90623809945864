import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-133f5069"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-orange-500" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "hidden lg:block" }
const _hoisted_4 = { class: "lg:hidden font-bold text-18" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseHeading, {
            class: "mt-5 lg:mt-0",
            size: 1
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('home_title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseButton, {
            class: "products-button",
            size: 1,
            variant: "secondary",
            color: "white",
            component: "router-link",
            to: _ctx.routes.productsList.path
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('home_list_button')), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('home_list_button_mobile')), 1),
              _createVNode(_component_BaseIcon, {
                icon: "arrow",
                class: "w-2 ml-1 rotate-180 hidden lg:block"
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(_component_BaseImage, {
          icon: "home_logo",
          alt: "Homepage",
          class: "home-image"
        })
      ]),
      _: 1
    })
  ]))
}