import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d77d5d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = { class: "h-full flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseContainer, { class: "categories-wrapper" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: _ctx.routes.productsCategory.path(category.id),
            key: category.id,
            class: "card"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_BaseIcon, {
                  icon: _ctx.categoriesIcons[category.id] || _ctx.defaultCategoryIcon,
                  class: "categories-icon",
                  alt: category.title
                }, null, 8, ["icon", "alt"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_BaseText, {
                  class: "category-title",
                  innerHTML: category.title
                }, null, 8, ["innerHTML"])
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128)),
        (_ctx.isHome)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.routes.productsList,
              class: _normalizeClass(["categories-button", _ctx.categoriesButtonClass])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseText, { class: "lg:text-18" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('categories_all_products')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseIcon, {
                  icon: "arrow",
                  class: "button-icon"
                })
              ]),
              _: 1
            }, 8, ["to", "class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}