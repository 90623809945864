
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import LmRequestForm from './LmRequestForm.vue';

export default defineComponent({
  name: 'HomeLMRequest',
  props: {
    isValid: { type: Boolean, default: false },
  },
  components: {
    LmRequestForm,
  },
  emits: ['submit'],
  setup() {
    const { t } = useTranslations();
    return { t };
  },
});
