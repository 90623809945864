
import { defineComponent, PropType } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { Product } from '@/shared/interfaces/product';

export default defineComponent({
  name: 'NewProductCard',
  props: { product: { type: Object as PropType<Product>, required: true } },
  setup() {
    const { t } = useTranslations();

    return { routes, t };
  },
});
