
import { defineComponent, onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useCategories } from '@/pages/ProductsList/composables/useCategories';
import { useNewProducts } from '@/pages/NewProducts/composables/useNewProducts';
import { useOpenable } from '@/shared/composables/useOpenable';
import { useLmRequest } from './composables/useLmRequest';
import { lmRequestValidationSchema } from './composables/lmRequestValidationSchema';
import HomeLandingBlockLM from './components/HomeLandingBlockLM.vue';
import HomeNewProducts from './components/HomeNewProducts.vue';
import HomeLMRequest from './components/HomeLMRequest.vue';
import MapBlock from './components/MapBlock.vue';
import Categories from '@/shared/components/Categories/Categories.vue';
import RequestLmSuccessModal from './components/RequestLmSuccessModal.vue';
import { LM_REQUEST_INITIAL_DATA } from '@/shared/constants/lmRequest';
import { LmRequest } from '@/shared/interfaces/lmRequest';

const pageSize = 3;

export default defineComponent({
  name: 'HomeLM',
  components: {
    HomeLandingBlockLM,
    HomeNewProducts,
    HomeLMRequest,
    MapBlock,
    Categories,
    RequestLmSuccessModal,
  },
  setup() {
    const { categories } = useCategories();
    const { t } = useTranslations();
    const router = useRouter();
    const { products, getNewProducts } = useNewProducts();
    const { isOpen: showModal, open: openModal, close } = useOpenable();
    const { postRequest, isSuccess, error: requestError } = useLmRequest();
    const isLmRequested = ref(false);

    const { values, meta } = useForm({
      validationSchema: lmRequestValidationSchema(t),
      initialValues: LM_REQUEST_INITIAL_DATA,
      validateOnMount: false,
    });

    const onSubmit = async () => {
      await postRequest(values as LmRequest);
      if (isSuccess.value) openModal();
      else router.push({ path: routes.error.path });
    };

    const closeModal = () => {
      isLmRequested.value = true;
      close();
    };

    onMounted(async () => {
      const options = { page: 1, pageSize };
      getNewProducts(options);
    });

    return {
      categories,
      products,
      routes,
      t,
      onSubmit,
      meta,
      showModal,
      closeModal,
      isLmRequested,
      requestError,
    };
  },
});
