
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { routes } from '@/shared/constants/routes';

export default defineComponent({
  name: 'HomeLandingBlockLM',
  setup() {
    const { t } = useTranslations();
    return { t, routes };
  },
});
