
import { defineComponent, onMounted } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useCategories } from '@/pages/ProductsList/composables/useCategories';
import { useNewProducts } from '@/pages/NewProducts/composables/useNewProducts';
import HomeLandingBlock from './components/HomeLandingBlock.vue';
import MapBlock from './components/MapBlock.vue';
import Categories from '@/shared/components/Categories/Categories.vue';

const pageSize = 3;

export default defineComponent({
  name: 'HomeMarkets',
  components: {
    HomeLandingBlock,
    MapBlock,
    Categories,
  },
  setup() {
    const { categories } = useCategories();
    const { t } = useTranslations();
    const { products, getNewProducts } = useNewProducts();

    onMounted(async () => {
      const options = { page: 1, pageSize };
      getNewProducts(options);
    });

    return {
      categories,
      products,
      routes,
      t,
    };
  },
});
