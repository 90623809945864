import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30c65f2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  x: "-145",
  y: "24",
  class: "container",
  width: "280",
  height: "192"
}
const _hoisted_2 = { class: "tooltip" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("foreignObject", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseText, {
        variant: "18-20",
        class: "font-bold block mb-1.5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.location.name}`)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, {
        variant: "18-20",
        class: "block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.location.street}`)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, {
        variant: "18-20",
        class: "block mb-1.5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.location.city}`)), 1)
        ]),
        _: 1
      }),
      _createElementVNode("a", {
        class: "flex itams-center",
        href: _ctx.location.mapLink
      }, [
        _createVNode(_component_BaseText, { variant: "18-20" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('map_google_link')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseIcon, {
          icon: "arrow",
          class: "icon"
        })
      ], 8, _hoisted_3)
    ])
  ]))
}