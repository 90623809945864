
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';
// import 'swiper/css/scrollbar';
import { Mousewheel, Scrollbar } from 'swiper';
import { ContactCarouselItem } from '@/shared/components/ContactCarousel/ContactCarouseltem';
import BaseText from '@/shared/components/BaseComponents/BaseText/BaseText.vue';

export default defineComponent({
  name: 'ContactCarousel',
  components: { BaseText, Swiper, SwiperSlide },
  props: {
    slides: {
      type: Array as PropType<ContactCarouselItem[]>,
      required: true,
    },
    allowOverflow: {
      type: Boolean,
      default: false,
    },
    swiperProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { Mousewheel, Scrollbar };
  },
});
